import { visibleBasedOnUrlParameters } from "../util/visibilityRules";

export default class PromoBanner {
  constructor() {
    this.selector = ".promo-banner";
  }

  bootstrap() {
    const isVisible = visibleBasedOnUrlParameters(promoBannerVisibilityRules);

    if (isVisible) {
      $(".promo-banner").removeClass("is-closed");
      $(".header").addClass("banner-open");
      $(".header-curtain").addClass("banner-open");
    } else {
      $(".promo-banner").addClass("is-closed");
      $(".header").removeClass("banner-open");
      $(".header-curtain").removeClass("banner-open");
    }
  }
}
