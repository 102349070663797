import $ from 'jquery';
import 'slick-carousel';

export default class TestimonialSlider {
  constructor() {
    this.selector = '.b-testimonials-slider';
  }

  bootstrap() {
    if ($(this.selector).length > 0) {
      $(this.selector).find('.b-testimonials-slider__items').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              dots: true,
              arrows: false,
            }
          },
        ]
      });
    }
  }
}
