export default class TocHighlight {
    constructor() {
      this.selector = '.article-toc';
    }
  
    bootstrap() {
    if ($(this.selector).length > 0) {
        function scrollSectionHightlighter() {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    const id = entry.target.getAttribute('id');
                    let visibleElement = document.querySelector(`.article-toc a[href="#${id}"]`);
                    let previousActiveToc = document.querySelector('.article-toc a.active-toc') ?? null;
        
                    if (!visibleElement) return;
        
                    if ((previousActiveToc != visibleElement) && entry.isIntersecting) {
                        if (previousActiveToc) {
                            previousActiveToc.classList.remove('active-toc');
                        }
                        visibleElement.classList.add('active-toc');
                    }
                });
            }, {
                threshold: 0.1
            });
        
            document.querySelectorAll('.article-content h2[id], .article-content h3[id]').forEach((section) => {
                observer.observe(section);
            });
        }
        window.addEventListener('scroll', () => {
            scrollSectionHightlighter();
        });
    }
    }
  }