import $ from 'jquery';
import 'slick-carousel';

export default class LogoCarousel {
  constructor() {
    this.selector = '.b-logo-carousel';
    this.slider = '.b-logo-carousel .b-logos__items';
  }

  bootstrap() {
    if ($(this.selector).length > 0) {
      let sliderSpeed = $(this.slider).data('speed') ?? 5000;
      $(this.selector).find('.b-logos__items').slick({
        centerMode: true,
        centerPadding: '80px',
        slidesToShow: 3,
        arrows: false,
        autoplay: true,
        autoplaySpeed: sliderSpeed,
        adaptiveHeight: true,
        responsive: [
            {
            breakpoint: 768,
            settings: {
                centerPadding: '100px'
                }
            },
            {
            breakpoint: 515,
            settings: {
                centerPadding: '70px'
                }
            },
            {
            breakpoint: 450,
            settings: {
                centerPadding: '60px'
                }
            },
            {
            breakpoint: 340,
            settings: {
                centerPadding: '40px'
                }
            }
        ]
    });
    }
  }
}
