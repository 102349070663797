export default class FeaturesListAccordion {
    constructor() {
      this.selector = '.b-featured-list-accordion';
      this.matrixGroup = $(this.selector).find('.matrix__group');
      this.showTableTrigger = $(this.selector).find('.pricing__matrix--features-dropdown');
      this.tableBodySelector = 'section.pricing__matrix--body';
      this.headerSelector = '.js-header';
      this.matixHeader = $(this.selector).find('.matrix__header');
    }
  
    bootstrap() {
      this.init();
    }

    init() {
        this.events();
    }

    events() {
      let that = this;

      $(this.matrixGroup).on('click', function() {
        let $accordion = $(this);
        let $subItems = $accordion.find('.matrix__group-items');

        //$('.matrix__group-items').removeClass('expanded');
        if($subItems.length > 0) {
          $accordion.toggleClass('subitems-shown');
        }
        $subItems.toggleClass('expanded');
      });

      $(this.showTableTrigger).on('click', function() {
        let $trigger = $(this);

        
        $trigger.toggleClass('pricing__matrix--features-dropdown--open');
        $(that.tableBodySelector).toggleClass('pricing__matrix--body--open');
      });

      // when in view
      let headerHeight = $(this.headerSelector).outerHeight();
      let matrixHeaderHeight = $(this.matixHeader).outerHeight();

      $(window).scroll(function() {
        let scrollTop = $(window).scrollTop();
        let $tableBody = $(that.tableBodySelector);
        let tableBodyTop = $tableBody.offset().top;
        let tableBodyBottom = tableBodyTop + $tableBody.outerHeight();

        if((scrollTop > tableBodyTop - headerHeight) && (scrollTop < tableBodyBottom - matrixHeaderHeight)) {
          $tableBody.addClass('pricing__matrix--body__in-view');
          $tableBody.css('padding-top', matrixHeaderHeight);
          $('.pricing__matrix--body__in-view > .matrix__header').css('top', headerHeight);
        } else if((scrollTop > tableBodyBottom - headerHeight) || (scrollTop < tableBodyTop - headerHeight)) {
          $tableBody.removeClass('pricing__matrix--body__in-view');
          $tableBody.css('padding-top', 0);
        } else {
          $tableBody.removeClass('pricing__matrix--body__in-view');
          $tableBody.css('padding-top', 0);
        }
      });

    }
  }
  