export default class Faq {
  constructor() {
    this.selector = '.b-faq';
  }

  bootstrap() {
    $(this.selector).find('.c-single-faq__question').click(function(e) {
      e.preventDefault();
    })

    $(this.selector).find('.c-single-faq').click(function() {
      
      if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).find('.c-single-faq__answer').slideUp();
      } else {
        $('.c-single-faq').each(function() {
          $(this).removeClass('active');
          $(this).find('.c-single-faq__answer').slideUp();
        })
    
        $(this).addClass('active');
        $(this).find('.c-single-faq__answer').slideDown(250);
      }
    })
  }
}
