export default class SearchBar {
    constructor() {
        this.selector = ".search-icon";
    }
    bootstrap() {
        if ($(this.selector).length > 0) {
            $(this.selector).on("click", function() {
                document.body.classList.toggle('searchbar-modal-open');
                document.querySelector('input[name="s"]').focus();
            });
            document.getElementsByClassName('search-backlight')[0].addEventListener("click", function() {
                document.body.classList.remove('searchbar-modal-open');
            });
        }
    }
  }