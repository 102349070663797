import $ from 'jquery';
import 'slick-carousel';

export default class FeaturedArticlesSlider {
  constructor() {
    this.selector = '.featured-articles';
  }

  bootstrap() {
    if ($(this.selector).find('.featured-article').length > 1) {
        let sliderSpeed = $(this.selector).data('speed') ?? 5000;
        $(this.selector).slick({
            arrows: true,
            autoplay: true,
            autoplaySpeed: sliderSpeed,
            dots: true,
            pauseOnDotsHover: true,
            draggable: true,
            fade: true,
            infinite: true,
            cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
            touchThreshold: 100
        });
    }
  }
}
