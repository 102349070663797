export default class ProgressBar {
  constructor() {
    this.selector = '.page-scroll-progress';
  }

  bootstrap() {
    if ($(this.selector).length > 0) {
        
        const progressBar = document.querySelector(this.selector);
        const section = document.querySelector('.post-content');

        if (section) {
          const scrollProgressBar = () => {
            let scrollDistance = -(section.getBoundingClientRect().top);
            let progressPercentage =
                (scrollDistance /
                    (section.getBoundingClientRect().height -
                        document.documentElement.clientHeight)) * 100;

            let val = Math.min(Math.floor(progressPercentage), 100);
            progressBar.value = val;

            if (val < 0) {
                progressBar.value = '0';
            }
          };

          document.addEventListener('scroll', scrollProgressBar);

        }
    }
  }
}
