export default class PassHeaderHeight {
    constructor() {
        this.selector = "header.header";
    }
    bootstrap() {
        if ($(this.selector).length > 0) {
            $(function() {
                document.body.style.setProperty('--headerNavHeight', $(this.selector).outerHeight(true) + 'px');
            })
        }
    }
  }