import { equalizeHeight } from "../utilities";

export default class IconsWithText {
  constructor() {
    this.selector = '.b-icons-with-text';
  }

  bootstrap() {
    $(this.selector).each(function() {
      const item = $(this).find('.b-icons-with-text__item');
      
      equalizeHeight(item);
      
      $(window).on('resize', function() {
        equalizeHeight(item);
      })
    })
  }
}
