import $ from 'jquery';
import 'slick-carousel';

export default class PricingHeroSlider {
  constructor() {
    this.selector = '.b-hero-pricing';
  }

  bootstrap() {
    if ($(this.selector).length > 0) {
      $(this.selector).find('.b-hero-pricing__logos').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        variableWidth: true,
        centerMode: true,
        autoplay: true,
        autoplaySpeed: 0,
        arrows: false,
        dots: false,
        speed: 9000,
        cssEase: 'linear',
      });
    }
  }
}
