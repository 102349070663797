export default class setMultiLingualDemoPage {
  constructor() {
    this.selector = 'a[href^="https://lp.singular.net/demo"]';
  }

  bootstrap() {
    if ($(this.selector).length > 0) {
        const demoBtns = document.querySelectorAll('a[href^="https://lp.singular.net/demo"]') ?? false;
        const websiteLanguage = document.documentElement.lang ?? false;
        const languageAndPath = {
            'pt': 'pt',
            'es': 'es',
            'ko': 'ko',
            'zh-cn': 'zh'
        }
        
        if ( !demoBtns || !websiteLanguage || !languageAndPath.hasOwnProperty(websiteLanguage) ) {
            return;
        }
        
        demoBtns.forEach((demoBtn) => demoBtn.href = demoBtn.href.replace('/demo', '/'+languageAndPath[websiteLanguage]+'/demo'));
    }
  }
}