import $ from 'jquery';
import 'slick-carousel';

export default class ClientTestimonialSlider {
  constructor() {
    this.selector = '.b-client-testimonials-slider';
  }

  bootstrap() {
    if ($(this.selector).length > 0) {
      $(this.selector).find('.testimonial-v2-slider').slick({
        dots: true,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 8000
    });
    }
  }
}
