import Player from "@vimeo/player";
import WistiaPlayerApiLoader from "wistia-player-api-loader";
import YouTubePlayer from "youtube-player";
import { inViewport } from "../utilities";

export default class Video {
  constructor() {
    this.selector = ".c-video";

    this.$modal = ".c-modal";

    this.videoClasses = {
      common: ".video-external",
      vimeo: "video-external--vimeo",
      youtube: "video-external--youtube",
      wistia: "video-external--wistia",
      playing: "playing",
    };

    this.youtubeVideos = [];
    this.vimeoVideos = [];
    this.wistiaVideos = [];
  }

  bootstrap() {
    this.init();
    this.setupModals();
  }

  init() {
    const videoCollection = [
      ...document.querySelectorAll(this.videoClasses.common),
    ];

    if (videoCollection.length === 0) {
      return;
    }

    videoCollection.forEach((video, idx) => {
      const type = this.getVideoType(video.getAttribute("class"));

      if (type === "vimeo") {
        this.vimeoVideos.push(this.handleVimeoVideos(video, idx));
      }

      if (type === "youtube") {
        this.youtubeVideos.push(this.handleYTVideos(video, idx));
      }

      if (type === "wistia") {
        this.wistiaVideos.push(this.handleWistiaVideo(video, idx));
      }
    });

    this.vimeoVideos.forEach((video) => {
      this.setAspectRatio(video);
    });

    this.youtubeVideos.forEach((video) => {
      this.setAspectRatio(video);
    });

    this.playVideosInViewport(videoCollection);
  }

  setupModals() {
    this.modalEvents($(this.$modal));
  }

  /**
   * Get video type
   * @param {String} className
   * @return {String/Null}
   */
  getVideoType(className) {
    const { vimeo, youtube, wistia } = this.videoClasses;

    if (className.search(vimeo) !== -1) {
      return "vimeo";
    }

    if (className.search(youtube) !== -1) {
      return "youtube";
    }

    if (className.search(wistia) !== -1) {
      return "wistia";
    }

    return null;
  }

  /**
   * Should include poster
   * @param {Node} video
   */
  // eslint-disable-next-line class-methods-use-this
  includePoster(video) {
    const poster = video.dataset.getPoster;

    if (poster) {
      return true;
    }
  }

  /**
   * Get video thumb
   * @param {Object} video
   * @param {String} type
   */
  // eslint-disable-next-line class-methods-use-this
  fetchThumbnails(video) {
    const { id, player, type } = video;

    // If type is vimeo
    if (type === "vimeo" && player) {
      const videoOriginalId = id.split("-")[1];

      player.ready().then(() => {
        $.ajax({
          type: "GET",
          url: `https://vimeo.com/api/v2/video/${videoOriginalId}.json`,
          jsonp: "callback",
          dataType: "jsonp",
          success(data) {
            const thumbnailUrl = data[0].thumbnail_large;

            $(`#${id} .video__placeholder`).attr(
              "style",
              `background-image: url(${thumbnailUrl})`
            );
          },
        });
      });
    }

    // If type is youtube
    if (type === "youtube" && player) {
      player.on("ready", (data) => {
        const videoId = data.target.playerInfo.videoData.video_id;
        const videoThumbnail = `//img.youtube.com/vi/${videoId}/hqdefault.jpg`;

        $(`#${id}`)
          .nextAll(".video__placeholder")
          .attr("style", `background-image: url(${videoThumbnail})`);
      });
    }
  }

  /**
   * Handle Vimeo Videos
   * @param {Node} video
   */
  handleVimeoVideos(video, idx) {
    if (!video) {
      return;
    }

    const wrapper = video.closest(this.selector);
    const playButton = video.querySelector(".btn-play");
    const id = video.dataset.videoId;
    const uniqueId = `player-${id}-${idx}`;
    const fetchPoster = this.includePoster(video);

    // Set unique id
    video.setAttribute("id", uniqueId);

    // Init player
    const player = new Player(uniqueId, {
      id,
    });

    playButton.addEventListener("click", (event) => {
      event.preventDefault();

      wrapper.classList.add(this.videoClasses.playing);

      player.play();
    });

    const videoObject = {
      id: uniqueId,
      player,
      type: "vimeo",
    };

    if (fetchPoster) {
      this.fetchThumbnails(videoObject);
    }

    return videoObject;
  }

  /**
   * Handle YT
   * @param {Node} video
   */
  handleYTVideos(video, idx) {
    if (!video) {
      return;
    }

    const wrapper = video.closest(this.selector);
    const id = video.dataset.videoId;
    const controls = video.dataset.controls;
    const uniqueId = `player-${id}-${idx}`;
    const initialHTML = video.innerHTML;
    const fetchPoster = this.includePoster(video);

    // Set unique id
    video.setAttribute("id", uniqueId);

    // Init player
    const player = new YouTubePlayer(uniqueId, {
      videoId: id,
      playerVars: { 
        'controls': controls
      },
    });

    video.insertAdjacentHTML("afterend", initialHTML);

    wrapper.addEventListener("click", (event) => {
      event.preventDefault();

      if (event.target.matches(".btn-play, .btn-play *")) {
        wrapper.classList.add(this.videoClasses.playing);

        player.playVideo();
      }
    });

    const videoObject = {
      id: uniqueId,
      player,
      type: "youtube",
    };

    if (fetchPoster) {
      this.fetchThumbnails(videoObject);
    }

    return videoObject;
  }

  /**
   * Handle Wistia
   * @param {Node} video
   */
  // eslint-disable-next-line class-methods-use-this
  handleWistiaVideo(video, idx) {
    if (!video) {
      return;
    }
    const id = video.dataset.videoId;
    const uniqueId = `player-${id}-${idx}`;

    // Set unique id
    video.setAttribute("id", uniqueId);

    WistiaPlayerApiLoader.load((Wistia) => {
      Wistia.embed(id, {
        container: uniqueId,
        videoFoam: true,
      });
    });

    return uniqueId;
  }

  /**
   * Set padding according to video ratio
   * @param {Object} video
   */
  setAspectRatio({ id, player, type }) {
    if (type === "vimeo") {
      player.ready().then(() => {
        const element = document.getElementById(id);
        const ratioPadding = this.getAspectRatio(player.element);

        element.classList.add("video--responsive");
        element.style.paddingTop = ratioPadding;
      });
    }

    if (type === "youtube") {
      player.on("ready", (data) => {
        const element = document.getElementById(id);
        const wrap = element.closest(this.selector);
        const ratioPadding = this.getAspectRatio(data.target.f);

        wrap.classList.add("video--responsive");
        wrap.style.paddingTop = ratioPadding;
      });
    }
  }

  /**
   * Get ratio from element
   * @param {Node} video
   */
  // eslint-disable-next-line class-methods-use-this
  getAspectRatio(video) {
    if (video) {
      const width = video.getAttribute("width");
      const height = video.getAttribute("height");

      return `${(height / width) * 100}%`;
    }

    return "56.25%";
  }

  /**
   * Play videos in viewport
   * @param {Array} videos
   */
  playVideosInViewport(videos) {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    $(window).on("scroll", () => {
      videos.forEach((video) => {
        const playInViewport = video.dataset.autoplay;
        const autoStop = video.dataset.autopause;
        const id = video.getAttribute("id");
        const type = this.getVideoType(video.getAttribute("class"));
        const players = this[`${type}Videos`];

        if (type === "vimeo") {
          players.forEach(({ id: videoId, player }) => {
            player.ready().then(() => {
              if (videoId === id) {
                const element = document.getElementById(videoId);

                // Play vimeo only if is set to autoplay and is in viewport
                if (playInViewport && inViewport(video)) {
                  element.querySelector(".btn-play").click();
                }

                // Pause all vimeo that are not in the viewport
                if (!inViewport(video) && autoStop) {
                  player.pause();
                }
              }
            });
          });
        }

        if (type === "youtube") {
          players.forEach(({ id: videoId, player }) => {
            if (videoId === id) {
              const element = document.getElementById(videoId);
              const wrap = element.closest(this.selector);

              // Play vimeo only if is set to autoplay and is in viewport
              if (playInViewport && inViewport(wrap)) {
                wrap.querySelector(".btn-play").click();
              }

              // Pause all vimeo that are not in the viewport
              if (!inViewport(wrap) && autoStop) {
                player.pauseVideo();
              }
            }
          });
        }

        if (type === "wistia") {
          players.forEach((videoId) => {
            const wistiaVideo = window.Wistia.api(videoId);

            if (!wistiaVideo || !wistiaVideo.ready()) {
              return;
            }

            if (id === videoId) {
              // Play vimeo only if is set to autoplay and is in viewport
              if (playInViewport && inViewport(video)) {
                wistiaVideo.play();
              }

              // Pause all vimeo that are not in the viewport
              if (!inViewport(video) && autoStop) {
                wistiaVideo.pause();
              }
            }
          });
        }
      });
    });
  }

  /**
   * Add listeners for modals with videos
   * @param {jQueryObject} $modal
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  modalEvents($modal) {
    $modal
      // eslint-disable-next-line sonarjs/cognitive-complexity
      .on("shown.bs.modal", ({ currentTarget }) => {
        const $video = $(currentTarget).find(this.videoClasses.common);

        if ($video.length === 0) {
          return;
        }

        const currVideoId = $video.attr("id");
        const playInViewport = $video.data("autoplay");
        const type = this.getVideoType($video.attr("class"));

        if (type === "youtube" && playInViewport) {
          this.youtubeVideos.forEach(({ id }) => {
            if (currVideoId === id) {
              const wrap = document.getElementById(id).closest(this.selector);

              wrap.querySelector(".btn-play").click();
            }
          });
        }

        if (type === "vimeo" && playInViewport) {
          this.vimeoVideos.forEach(({ id }) => {
            if (currVideoId === id) {
              document.getElementById(id).querySelector(".btn-play").click();
            }
          });
        }

        if (type === "wistia" && playInViewport) {
          this.wistiaVideos.forEach((id) => {
            if (currVideoId === id) {
              const wistiaVideo = window.Wistia.api(id);

              wistiaVideo.play();
            }
          });
        }
      })
      .on("hidden.bs.modal", ({ currentTarget }) => {
        const $video = $(currentTarget).find(this.videoClasses.common);

        if ($video.length === 0) {
          return;
        }

        const type = this.getVideoType($video.attr("class"));

        if (type === "vimeo") {
          this.vimeoVideos.forEach(({ player }) => {
            player.pause();
          });
        }

        if (type === "youtube") {
          this.youtubeVideos.forEach(({ player }) => {
            player.stopVideo();
          });
        }

        if (type === "wistia") {
          this.wistiaVideos.forEach((id) => {
            const wistiaVideo = window.Wistia.api(id);

            wistiaVideo.pause();
          });
        }
      });
  }
}
