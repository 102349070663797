import './vendor/bootstrap';
import Router from "./components/router";
import setMultiLingualDemoPage from "./util/setMultiLingualDemoPage";
import Video from "./components/video";
import LogoSlider from "./components/logoSlider";
import LogoCarousel from "./blocks/logoCarousel";
import ClientTestimonialSlider from "./blocks/clientTestimonialSlider";
import FeaturedArticlesSlider from "./blocks/featuredArticlesSlider";
import ResourcesSlider from "./components/resourcesSlider";
import BigLogosSlider from "./components/bigLogos";
import TestimonialSlider from "./blocks/testimonialSlider";
import PricingHeroSlider from './blocks/pricingHeroSlider';
import Faq from "./blocks/faq";
import IconsWithText from "./blocks/iconsWithText";
import PromoBanner from './components/promoBanner';
import buttonAudioPlayer from './components/buttonAudioPlayer';
import FeaturesListAccordion from "./blocks/featuresListAccordion";
import ProgressBar from "./components/progressBar";
import TocHighlight from "./components/tocHighlight";
import PassHeaderHeight from "./components/passHeaderHeight";
import SearchBar from "./components/searchBar";

$(document).on("ready", () => {
  const router = new Router([
    new setMultiLingualDemoPage(),
    new Video(),
    new LogoSlider(),
    new LogoCarousel(),
    new ClientTestimonialSlider(),
    new FeaturedArticlesSlider(),
    new TestimonialSlider(),
    new PricingHeroSlider(),
    new ProgressBar(),
    new TocHighlight(),
    new PassHeaderHeight(),
    new SearchBar(),
    new Faq(),
    new IconsWithText(),
    new PromoBanner,
    new buttonAudioPlayer(),
    new FeaturesListAccordion(),
    new ResourcesSlider,
    new BigLogosSlider,
  ]);

  router.bootstrap();
});
