import 'bxslider/dist/jquery.bxslider.min.js';

export default class LogoSlider {
  constructor() {
    this.selector = '.b-logos';
    this.slider = '.b-logos .b-logos__items';
  }

  bootstrap() {
    if ($(this.selector).length > 0) {
        let sliderSpeed = $(this.slider).data('speed');
        $(this.slider).bxSlider({
            controls: false,
            pager: false,
            ticker: true,
            speed: sliderSpeed,
            touchEnabled: false,
            oneToOneTouch: false,
            minSlides: 2,
            maxSlides: 7,
            shrinkItems: true,
            easing: 'linear',
            slideWidth: 0,
        });
    }
  }
}

