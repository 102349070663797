/**
 * Whether a visibility rule passes based on the given value
 *
 * @param {Object} rule
 * @param {String} value
 * @return {Boolean}
 */
function visibilityRulePasses(rule, value) {
  if (!value) {
    return true;
  }

  let { value: ruleValue, operator } = rule;

  /**
   * Make both values all lowercase so that the comparison is case-insensitive
   */
  ruleValue = ruleValue.toLowerCase();
  value = value.toLowerCase();

  switch (operator) {
    case "equals":
      return ruleValue === value;

    case "contains":
      return value.includes(ruleValue);

    case "starts_with":
      return value.startsWith(ruleValue);

    case "doesnt_equal":
      return ruleValue !== value;

    case "doesnt_contain":
      return !value.includes(ruleValue);

    case "doesnt_start_with":
      return !value.startsWith(ruleValue);

    default:
      return true;
  }
}

/**
 * Whether a section should be visible based on URL parameters
 *
 * @param {Array} url_parameters
 * @return {Boolean}
 */
export function visibleBasedOnUrlParameters(urlParameterRules) {
  if (!urlParameterRules) {
    return true;
  }

  urlParameterRules = urlParameterRules.rules ? urlParameterRules.rules : [];

  const urlParameters = new URLSearchParams(window.location.search);

  for (let i = 0; i < urlParameterRules.length; i++) {
    const rule = urlParameterRules[i];
    if (urlParameters.has(rule.parameter)) {
      const value = urlParameters.get(rule.parameter);

      if (!visibilityRulePasses(rule, value)) {
        return false;
      }
    }
  }

  return true;
}
