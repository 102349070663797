export default class buttonAudioPlayer {
  constructor() {
    this.selector = ".button--audio-player";
  }

  bootstrap() {
    if ($(this.selector).length > 0) {
        $('.card-ui').on( "click", this.selector, function(e) {
          e.target.classList.add('active')
          e.target.nextElementSibling.getElementsByTagName('audio')[0].play();
        });
    }
  }
}
