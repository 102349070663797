import $ from 'jquery';
import 'slick-carousel';

export default class BigLogosSlider {
    constructor() {
        this.selector = '.b-big-logos';
    }

    bootstrap() {
        if ($(this.selector).length > 0) {
            let sliderWrap = $(this.selector).find('.b-big-logos__cnt');
            if (window.innerWidth < 992) {
                sliderWrap.slick({
                    speed: 300,
                    autoplay: false,
                    autoplaySpeed: 0,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: true,
                    centerMode: true,
                    arrows: false,
                    dots: true
                });
            }
            $(window).resize(function (e) {
                if (window.innerWidth < 992) {
                    if (!sliderWrap.hasClass('slick-initialized')) {
                        sliderWrap.slick({
                            speed: 300,
                            autoplay: false,
                            autoplaySpeed: 0,
                            slidesToShow: 1,
                            variableWidth: true,
                            centerMode: true,
                            arrows: false,
                            dots: true
                        });
                    }
                } else {
                    if (sliderWrap.hasClass('slick-initialized')) {
                        sliderWrap.slick('unslick');
                        jQuery('.b-big-logo .row').load(window.location.href + ' .b-big-logos__cnt')
                    }
                }
            });
        }
    }
}
